import React from 'react';

import * as styles from './index.module.css';

const ArticleListCardSeriesLabel = ({ series }) => {
  return (
    <div>
      <span className={styles['label']}>{series.title}</span>
    </div>
  );
};

export default ArticleListCardSeriesLabel;
