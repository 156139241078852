import React from 'react';

import ArticleListCard from '../../molecules/article/ArticleListCard';

import * as styles from './index.module.css';

const ArticleList = ({ articles, eager }) => {
  return (
    <div>
      <ul className={styles['list']}>
        {articles.map((article, index) => (
          <li className={styles['listItem']} key={article.id}>
            <ArticleListCard article={article} eager={!!eager && index === 0} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ArticleList;
