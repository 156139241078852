// extracted by mini-css-extract-plugin
export var categoryAndPublishdAtWrapper = "index-module--category-and-publishd-at-wrapper--Qem51";
export var contentWrapper = "index-module--content-wrapper--Q65Rb";
export var expertsGroupWrapper = "index-module--experts-group-wrapper---2StB";
export var link = "index-module--link--6uTAs";
export var mainImageWrapper = "index-module--main-image-wrapper--LhOND";
export var prBadgeWrapper = "index-module--pr-badge-wrapper--D4+PF";
export var providedBySpoanBadgeWrapper = "index-module--provided-by-spoan-badge-wrapper--WG4T4";
export var root = "index-module--root--x0Bfk";
export var seriesLabelWrapper = "index-module--series-label-wrapper--hYojP";
export var title = "index-module--title--eQoef";