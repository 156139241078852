import React, { useState, useCallback } from 'react';
import { Link } from 'gatsby';

import InlineBlockWrapper from '../../../components/InlineBlockWrapper';

import ObjectFitCoverImage from '../../common/ObjectFitCoverImage';
import PRBadge from '../../common/PRBadge';
import ProvidedBySpoAnBadge from '../../common/ProvidedBySpoAnBadge';
import ExpertNameplateGroup from '../../common/ExpertNameplateGroup';
import CategoryAndPublishedAt from '../../common/CategoryAndPublishedAt';

import ArticleListCardSeriesLabel from '../ArticleListCardSeriesLabel';

import * as styles from './index.module.css';

const ArticleListCard = ({ article, eager }) => {
  const [linkStyle, setLinkStyle] = useState({});

  const {
    id,
    title,
    slug,
    mainImage,
    publishedAt,
    category,
    series,
    interviewees,
    teachers,
    authors,
    featuredType,
    providedBySpoAn,
  } = article;

  const onLinkMouseEnter = useCallback(() => {
    setLinkStyle({
      opacity: 0.75,
    });
  }, []);

  const onLinkMouseLeave = useCallback(() => {
    setLinkStyle({});
  }, []);

  return (
    <div className={styles['root']}>
      <Link
        className={styles['link']}
        to={`/article/${slug}`}
        style={linkStyle}
        onMouseEnter={onLinkMouseEnter}
        onMouseLeave={onLinkMouseLeave}
      >
        <div className={styles['mainImageWrapper']}>
          <ObjectFitCoverImage
            src={mainImage.resize.src}
            alt=""
            width={600}
            height={315}
            loading={eager ? 'eager' : 'lazy'}
          />
        </div>

        <div className={styles['contentWrapper']}>
          {series && (
            <div className={styles['seriesLabelWrapper']}>
              <ArticleListCardSeriesLabel series={series} />
            </div>
          )}

          <div>
            <span className={styles['title']}>{title}</span>
          </div>

          {(() => {
            if (featuredType === 'advertorial') {
              return (
                <InlineBlockWrapper className={styles['prBadgeWrapper']}>
                  <PRBadge />
                </InlineBlockWrapper>
              );
            }

            if (providedBySpoAn) {
              return (
                <InlineBlockWrapper
                  className={styles['providedBySpoanBadgeWrapper']}
                >
                  <ProvidedBySpoAnBadge />
                </InlineBlockWrapper>
              );
            }

            return null;
          })()}

          {interviewees && (
            <div className={styles['expertsGroupWrapper']}>
              <ExpertNameplateGroup
                role="インタビュイー"
                experts={interviewees}
                keyPrefix={id}
              />
            </div>
          )}

          {teachers && (
            <div className={styles['expertsGroupWrapper']}>
              <ExpertNameplateGroup
                role="講師"
                experts={teachers}
                keyPrefix={id}
              />
            </div>
          )}

          {authors && (
            <div className={styles['expertsGroupWrapper']}>
              <ExpertNameplateGroup
                role="筆者"
                experts={authors}
                keyPrefix={id}
              />
            </div>
          )}

          <div className={styles['categoryAndPublishdAtWrapper']}>
            <CategoryAndPublishedAt
              category={category}
              publishedAt={publishedAt}
            />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ArticleListCard;
